import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router-dom';
import darkLogo from './../../Assets/Images/ZURAH-LOGO.png';


// const TitleMetaTags = () => {
//   const location = useLocation();
//   const url = location.pathname; 

//   console.log(window.location.pathname);
  
  
// const metaTags = {
//     '/': {
//       title: 'Fine Jewelry & Diamond Collections | Zurah Jewellery ',
//       keywords: 'Lab-grown diamonds, Certified lab-grown diamonds, Chemically identical diamonds, Sustainable diamonds, Eco-friendly diamonds, Lab-grown diamond properties, Brilliant lab-grown diamonds, Sparkling lab-grown diamonds, Engagement rings collection, Wedding ring collection, Luxury earrings collection, Diamond pendant collection, Fine jewelry collections, Most loved jewelry, Popular diamond rings, Jewelry worn on the finger, Earrings, neck rings, arm rings, Popular jewelry pieces, Handcrafted diamond jewelry, Lab-grown diamond rings, Modern crafted jewelry, Fine diamond necklaces, Custom diamond earrings, Wedding jewelry collection, Bridal jewelry, Elegant wedding rings, Wedding accessories, Diamond investment value, Diamond jewelry as investment, Best diamond investments, Gold and diamond jewelry for festivals, Customer engagement moments, Engagement photos, Real engagement stories, Worldwide engagement celebrations, Customer jewelry moments',
//       description: 'Zurah Jewellery offers handcrafted engagement rings, stunning wedding bands, and premium fine jewelry. Celebrate life’s moments with brilliance.',
//       image: darkLogo,
//     },
//     '/products/JEWEL': {
//       title: 'Latest Gold & Diamond Jewellery Collection | Zurah Jewellery',
//       keywords: 'Lab-grown diamonds, Certified lab-grown diamonds, Chemically identical diamonds, Sustainable diamonds, Eco-friendly diamonds, Lab-grown diamond properties, Brilliant lab-grown diamonds, Sparkling lab-grown diamonds, Engagement rings collection, Wedding ring collection, Luxury earrings collection, Diamond pendant collection, Fine jewelry collections, Most loved jewelry, Popular diamond rings, Jewelry worn on the finger, Earrings, neck rings, arm rings, Popular jewelry pieces, Handcrafted diamond jewelry, Lab-grown diamond rings, Modern crafted jewelry, Fine diamond necklaces, Custom diamond earrings, Wedding jewelry collection, Bridal jewelry, Elegant wedding rings, Wedding accessories, Diamond investment value, Diamond jewelry as investment, Best diamond investments, Gold and diamond jewelry for festivals, Customer engagement moments, Engagement photos, Real engagement stories, Worldwide engagement celebrations, Customer jewelry moments',
//       description: 'Learn more about UPQOR, a leading IT company offering innovative solutions for businesses.',
//     },
//     '/services': {
//       title: 'UPQOR | Our Services',
//       keywords: 'UPQOR services, IT solutions, software development, store management',
//       description: 'Discover UPQOR’s range of services, including eCommerce solutions and business management tools.',
//     },
//     '/contact': {
//       title: 'UPQOR | Contact Us',
//       keywords: 'Contact UPQOR, business inquiries, software solutions, Surat IT company',
//       description: 'Get in touch with UPQOR for innovative business solutions and IT services.',
//     },
//   };

//   const currentMeta = metaTags[url] || metaTags['/'];

//   return (
//     <Helmet>
//       <title>{currentMeta.title}</title>
//       <meta name="description" content={currentMeta.description} />
//       <meta name="keywords" content={currentMeta.keywords} />
//       <meta property="og:title" content={currentMeta.title} />
//       <meta property="og:image" content={currentMeta.image} />
//       <meta property="og:description" content={currentMeta.description} />
//       <meta name="twitter:title" content={currentMeta.title} />
//       <meta name="twitter:description" content={currentMeta.description} />
//       <meta name="twitter:image" content={currentMeta.image} />
//       <meta name="twitter:card" content="summary_large_image" />
//     </Helmet>
//   );
// };

const TitleMetaTags = () => {
    const [metaData, setMetaData] = useState({
      title: '',
      description: '',
      keywords: '',
      image: darkLogo,
    });
  
    const location = useLocation(); 

    const metaConfig = {
      '/': {
        title: 'Fine Jewelry & Diamond Collections | Zurah Jewellery',
        keywords: 'Lab-grown diamonds, Certified lab-grown diamonds, Chemically identical diamonds, Sustainable diamonds, Eco-friendly diamonds, Lab-grown diamond properties, Brilliant lab-grown diamonds, Sparkling lab-grown diamonds, Engagement rings collection, Wedding ring collection, Luxury earrings collection, Diamond pendant collection, Fine jewelry collections, Most loved jewelry, Popular diamond rings, Jewelry worn on the finger, Earrings, neck rings, arm rings, Popular jewelry pieces, Handcrafted diamond jewelry, Lab-grown diamond rings, Modern crafted jewelry, Fine diamond necklaces, Custom diamond earrings, Wedding jewelry collection, Bridal jewelry, Elegant wedding rings, Wedding accessories, Diamond investment value, Diamond jewelry as investment, Best diamond investments, Gold and diamond jewelry for festivals, Customer engagement moments, Engagement photos, Real engagement stories, Worldwide engagement celebrations, Customer jewelry moments',
        description:
          'Zurah Jewellery offers handcrafted engagement rings, stunning wedding bands, and premium fine jewelry. Celebrate life’s moments with brilliance.',
        image: darkLogo,
      },
    };
  
    useEffect(() => {
      const pathname = location.pathname;
      //console.log(window.location.pathname);
  
      const formatSegment = (segment) => {
        const cleanedSegment = segment.replace(/-pv\w+$/, '');
        return cleanedSegment
          .split('-')
          .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
          .join(' ');
      };
  
      const generateDynamicMeta = (path) => {
        if (metaConfig[path]) {
          return metaConfig[path];
        }
  
        const segments = path.split('/').filter((segment) => segment);
        const formattedSegments = segments.map((segment, index) => {
          if (index === 0 && segment.toLowerCase() === 'products') {
            return 'Products';
          }
          return formatSegment(segment);
        });
  
        const dynamicTitle = `Zurah Jewellery | ${formattedSegments.join(' - ')}`;
        return {
          title: dynamicTitle,
          description: 'Explore the finest jewelry collections including engagement rings, wedding bands, and more.',
          keywords: 'Jewelry, Diamonds, Engagement rings, Wedding bands, Fine jewelry',
          image: darkLogo,
        };
      };
  
      const currentMeta = generateDynamicMeta(pathname);
      setMetaData(currentMeta);
    }, [location.pathname]);
  
    return (
      <Helmet>
        <title>{metaData.title}</title>
        <meta name="description" content={metaData.description} />
        <meta name="keywords" content={metaData.keywords} />
        <meta property="og:title" content={metaData.title} />
        <meta property="og:image" content={metaData.image} />
        <meta property="og:description" content={metaData.description} />
        <meta name="twitter:title" content={metaData.title} />
        <meta name="twitter:description" content={metaData.description} />
        <meta name="twitter:image" content={metaData.image} />
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet>
    );
  };


export default TitleMetaTags;
